import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';

import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';

import HeroImage from '../../images/use-cases/heroes/acquirers.png';
import PageComponent from '../../components/use-cases/PageComponent';


/*
 * Constants
 */
const features = [
    {
        key: 'overview',
        logoImg: <StaticImage src="../../images/use-cases/features/overview.png" placeholder="none" alt="" />,
        title: <Trans>Stay competitive with the latest tech.</Trans>,
        name: <Trans>Overview</Trans>,
        content: (
            <Trans>
                As a legacy company, it can be hard to keep up with increasing innovation taking place in the payments
                industry. By integrating with us you can leave that complexity behind and not worry about it ever again.
                We provide state-of-art technology so that you can offer innovative solutions to your clients. All
                rebranded to match your identity.
            </Trans>
        ),
        link: {
            url: 'https://switchpayments.com/docs/overview',
            text: <Trans>Discover Switch</Trans>,
        },
    },
    {
        key: 'terminal',
        logoImg: <StaticImage src="../../images/use-cases/features/terminal.png" placeholder="none" alt="" />,
        title: <Trans>Provide a truly omnichannel experience.</Trans>,
        name: <Trans>Terminal</Trans>,
        content: (
            <Trans>
                With a single infrastructure, your merchants can manage their entire terminal fleet, along with their
                e-commerce operation, using the same platform. All of this thanks to an innovative and PCI-certified
                architecture that allows Switch to divide the typical communication flow into two steps, leveraging the
                fully agnostic and cloud-based Switch Platform. As well as all its components.
            </Trans>
        ),
        link: {
            url: '/product/terminal',
            text: <Trans>Discover Terminal</Trans>,
        },
    },
    {
        key: 'processing',
        logoImg: <StaticImage src="../../images/use-cases/features/processing.png" placeholder="none" alt="" />,
        title: <Trans>Deploy your custom payment channels.</Trans>,
        name: <Trans>Processing</Trans>,
        content: (
            <Trans>
                Our system was designed so you can develop and deploy alternative payment schemes and custom transaction
                flows for POS remotely, leveraging the Switch Dynamic Forms capabilities.
            </Trans>
        ),
        link: {
            url: '/product/processing',
            text: <Trans>Discover Processing</Trans>,
        },
    },
];


/*
 * Public Elements
 */
const MerchantsUseCasePage = ({data}) => {
    const {t} = useTranslation();

    const title = t('Acquirers');
    const quantifier = t('dozens');
    const subtitle = t(
        'More than 50 Acquirers are optimizing their payments operations. Don\'t fall behind, join them.',
    );

    const metatags = {
        title: t('White Label Payment Gateway for Acquirers - Switch'),
        description: t('White label payment gateway solution for acquirers, PSPs, and ISVs. Customize your accounts to '
            + 'match a custom visual identity.'),
        // eslint-disable-next-line i18next/no-literal-string
        image: '/website/metatags/use-cases-acquirers.png',
    };

    return (
        <PageComponent
            data={data}
            image={HeroImage}
            title={title}
            quantifier={quantifier}
            subtitle={subtitle}
            features={features}
            metatags={metatags}
        />
    );
};

MerchantsUseCasePage.propTypes = {
    data: PropTypes.object.isRequired,
};


/*
 * Exports
 */
export default MerchantsUseCasePage;
export const query = graphql`
    query($language: String!, $useCaseType: String!) {
        ...UseCaseFragment
    }
`;
